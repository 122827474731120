/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
// utils
import truncateText from '../utils/truncateText';
// components
import Footer from "./footer"
import MadeBy from "./MadeBy"
import SinglePageHeader from './SinglePageHeader';
// css
import "./normalize.css"
import "./main.css"


const SinglePageLayout = ({ children }) => {
  useEffect(() => {
    // limit number of charaters in single page's description (in hero section)
    document.querySelector('p').innerText = truncateText('.page-desc', 233);
  }, [])

  return (
    <div className="container-main">
      <SinglePageHeader />
      {children}
      <Footer />
      <MadeBy />
    </div>
  )
}

SinglePageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SinglePageLayout