export default function collapseList() {
    // FAQ Collapse feature
    const questionsTable = document.querySelector('.collapse-table');
    questionsTable.addEventListener('click', (e) => {
        // check if clicked element is title or content div block
        if(e.target.classList.contains('collapse-title') || e.target.classList.contains('collapse-content')) {
            const contentElement = e.target.parentElement.querySelector('.collapse-content');
            contentElement.classList.toggle('hidden');

            // rotate the chevron icon
            const collapseIcon = e.target.parentElement.parentElement.querySelector('.collapse-arrow-icon');
            return collapseIcon.classList.toggle('uncollapsed');
        }

        // check if clicked element is icon div blocks
        if(e.target.classList.contains('container-collapse-icon')) {
            const contentElement = e.target.parentElement.querySelector('.collapse-content');
            contentElement.classList.toggle('hidden');

            // rotate the chevron icon
            const collapseIcon = e.target.querySelector('.collapse-arrow-icon');
            return collapseIcon.classList.toggle('uncollapsed');
        }

        // check if clicked element is collapse icon itself
        if(e.target.classList.contains('collapse-arrow-icon')) {
            const contentElement = e.target.parentElement.parentElement.querySelector('.collapse-content');
            contentElement.classList.toggle('hidden');

            // rotate the chevron icon
            return e.target.classList.toggle('uncollapsed');
        }
    })
}