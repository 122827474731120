import React, { useEffect } from 'react'
import { Link } from "gatsby"
import SinglePageLayout from '../components/SinglePageLayout';
import SEO from '../components/seo';
import arrowIcon from "../icons/arrow.svg"
import deleteIcon from "../icons/delete-icon.svg"
import editIcon from "../icons/edit-icon.svg"
import settingsIcon from "../icons/settings-icon.svg"
import triggerIcon from "../icons/power-icon.svg"

// utils
import collapseList from '../utils/collapseList';

function HowToUsePage() {

    useEffect(() => {
        // Collapse Items
        collapseList()
    }, [])

    return (
        <SinglePageLayout>
            <SEO title={`Toggol — How To Use "Toggol" Desktop App (Guide)`} description="Learn more about toggol and how to setup and start using in on your desktop. Access all your smart devices on your PC!" />
            <div className="container-page">
                <div className="page-hero hero">
                    <h1 className="page-title">How To Use</h1>
                    <p className="page-desc">Learn more about how to setup and use Toggol App. This page contains detailed setup instructions and answers on freaquently asked questions.</p>
                </div>
                <div className="page-content how-to-use-page">
                    <div className="quick-links">
                        <h4 className="my-3">Quick links:</h4>
                        <ol className="list mobile-list">
                            <li><Link className="text-underline link" to="#how-to-install">How To Install</Link></li>
                            <li><Link className="text-underline link" to="#how-to-register">How To Register</Link></li>
                            <li><Link className="text-underline link" to="#how-to-add">How To Add New Event</Link></li>
                            <li><Link className="text-underline link" to="#how-to-trigger">How To Trigger Event</Link></li>
                            <li><Link className="text-underline link" to="#how-to-shortcut">How To Change Search Bar Shortcut</Link></li>
                            <li><Link className="text-underline link" to="#how-to-system-start">How To Launch Toggol App on System Start</Link></li>
                            <li><Link className="text-underline link" to="#forgot-password">Forgot Password? How To Restore It?</Link></li>
                            <li><Link className="text-underline link" to="#how-to-update">Update available? How To Update The App?</Link></li>
                            <li><Link className="text-underline link" to="#minimized-app">Closed The App And Can Not Find It Anymore</Link></li>
                            <li><Link className="text-underline link" to="#how-to-edit-remove">How To Edit or Remove an Event</Link></li>
                            <li><Link className="text-underline link" to="#what-is-endpoint">What Is The Endpoint</Link></li>
                        </ol>
                    </div>
                    <div id="how-to-install" className="p-contaner anchor">
                        <h2 className="title-left-separator">How To Install</h2>
                        <p>There is nothing special for the installation process if you are already familiar with that. Just follow instructions.</p>
                        <div className="my-4">
                            <h3 className="my-1">macOS:</h3>
                            <ol className="list">
                                <li>Download the installation file and launch it.</li>
                                <li>Drag and drop the Toggol App icon to the Application folder as the installation wizard advice.</li>
                                <li><strong className="text-accent">IMPORTANT!</strong> In order to use the Toggol App you should allow it in System Preferences. Open <span className="btn btn-sm none-hover">System Preferences</span> &gt; <span className="btn btn-sm none-hover">Security & Privacy</span> &gt; Click the lock <span className="btn btn-sm none-hover">🔒</span> icon to unlock it &gt; Press <span className="btn btn-sm none-hover">Allow Toggol</span> in right bottom corner of the window.</li>
                                <li>Done! Now you can launch and use it.</li>
                            </ol>
                        </div>
                        <div className="my-4">
                            <h3 className="my-1">Windows:</h3>
                            <ol className="list">
                                <li>Download the installation file and launch it.</li>
                                <li>Keep pressing <span className="btn btn-sm none-hover">Continue</span> as the installation wizard advises until you are done with the installation.</li>
                                <li>Done! Now you can launch and use it.</li>
                            </ol>
                            <p>If the installation file or app itself doesn't launch, try to right-click it and <span className="btn btn-sm none-hover">Open as administrator</span></p>
                        </div>
                        <div className="my-4">
                            <h3 className="my-1">Linux:</h3>
                                <ol className="list">
                                <li>Download the installation file and launch it.</li>
                                <li>Keep press <span className="btn btn-sm none-hover">Continue</span> as the installation wizard advises until you are done with the installation.</li>
                                <li>Done! Now you can launch and use it.</li>
                            </ol>
                        </div>
                    </div>
                    <div id="how-to-register" className="p-contaner anchor">
                        <h2 className="title-left-separator">How To Register</h2>
                        <p>After installation, launch the app and create a new account by clicking <span className="btn btn-sm none-hover">Don't have an account?</span> link under <span className="btn btn-sm none-hover">Sign In</span> button.
                        Fill out all required fields and click <span className="btn btn-sm none-hover">Sign Up</span>.</p>
                    </div>
                    <div id="how-to-add" className="p-contaner anchor">
                        <h2 className="title-left-separator">How To Add New Event</h2>
                        <div>
                            To add a new event launch the app and open the main window of the application. In the left corner of the window click the <span className="btn btn-sm none-hover">+</span> button and a pop-up window should open.
                            <br />
                            <br />
                            Enter all required fields:
                            <br />
                            <ol className="list">
                                <li>Title: name of your event you're about to create {`(`}for example: Bedroom Light ON{`)`}</li>
                                <li>Endpoint: endpoint URL of the event you have created at IFTTT, Zapier or other platform. If you don't have an endpoint yet, learn how to create one here:</li>
                            </ol>
                            <div id="how-to-endpoint" className="collapse-table anchor">
                                <h3 className="mt-3">How To Create An Endpoint</h3>
                                <ul className="mobile-list no-click-highlight">
                                    <li className="my-5 pb-1 pt-1">
                                        <div><div className="collapse-title noselect">IFTTT</div>
                                        <div className="collapse-content hidden">
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/QtlUcXXMtrs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            <ol className="list my-4">
                                                <li>Visit <a className="link text-underline" target="_blank" href="https://ifttt.com">https://ifttt.com</a></li>
                                                <li>Choose <span className="btn btn-sm none-hover">Create</span> in header navigation menu</li>
                                                <li>Choose <span className="btn btn-sm none-hover">Applets</span></li>
                                                <li>Click on <span className="btn btn-sm none-hover">This</span> text-button {`(If + <This> Then That)`}</li>
                                                <li>Search or just choose <span className="btn btn-sm none-hover">WebHooks</span> from the list of all services</li> 
                                                <li>Choose trigger <span className="btn btn-sm none-hover">Receive a web request</span></li>
                                                <li>Enter an Event Name in the input field {`(`}for example: bedroom_light_on{`)`} and <span className="btn btn-sm none-hover">Create Trigger</span></li>
                                                <li>Click on <span className="btn btn-sm none-hover">Then</span> text-button {`(If This Then + <That>)`}</li>
                                                <li>Choose your device which you want to trigger {`(`}in our case it will be <span className="btn btn-sm none-hover">LIFX</span> - the smart bulb{`)`}. It can be Philips hue or whatever smart device you have</li>
                                                <li>Choose an action you would like to trigger {`(`}in our case it will be <span className="btn btn-sm none-hover">Turn lights on</span>{`)`}</li>
                                                <li>Setup your action fields {`(`}in our case we should choose which light we want to trigger, fade duration, color, brightness and advanced options{`)`}. Click the <span className="btn btn-sm none-hover">Create Action</span> button.</li>
                                                <li>Copy/Remember your trigger's name {`(`}bedroom_light_on{`)`} and click the <span className="btn btn-sm none-hover">Finish</span> button.</li>
                                                <li>Make sure you action slider says <span className="btn btn-sm none-hover">Connected</span></li>
                                                <li>Visit <a className="link text-underline" target="_blank" href="https://ifttt.com/maker_webhooks">https://ifttt.com/maker_webhooks</a></li>
                                                <li>Click on <span className="btn btn-sm none-hover">Documentation</span> button in right top corner</li>
                                                <li>Enter your trigger's name from step 12 {`(`}in our case: bedroom_light_on{`)`} into the input field of your personal POST/GET web request {`(`}see screenshot{`)`}.</li>
                                                <li>We are done! Copy the whole Webhook URL {`(`}like this: <span className="text-underline">https://maker.ifttt.com/trigger/bedroom_light_on/with/key/your-secret-key</span>{`)`} and create a new event inside Toggol's App using that url.</li>
                                            </ol>
                                        </div>
                                        </div>
                                        <div className="container-collapse-icon"><img src={arrowIcon} alt="arrow-icon" className="collapse-arrow-icon" /></div>
                                    </li>
                                    <li className="my-5 pb-1 pt-1">
                                        <div><div className="collapse-title noselect">Zapier</div>
                                        <div className="collapse-content hidden">
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/Ft_FB67WVJ8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            <ol className="list my-4">
                                                <li>Visit <a className="link text-underline" target="_blank" href="https://zapier.com/app/dashboard">https://zapier.com/app/dashboard</a></li>
                                                <li>Click <span className="btn btn-sm none-hover">Make A Zap</span> button</li>
                                                <li>Choose <span className="btn btn-sm none-hover">Webhooks by Zapier</span> {`(`}currently webhooks available only for premium zapier users, so if you don't have premium yet consider purchasing one or use IFTTT web hooks service, which is currently free{`)`}.</li>
                                                <li>Choose <span className="btn btn-sm none-hover">Catch Hook</span> inside the <span className="btn btn-sm none-hover">Choose Trigger Event</span> field and press <span className="btn btn-sm none-hover">Continue</span> button.</li>
                                                <li>Enable <span className="btn btn-sm none-hover">Silent Mode</span> by clicking on the check mark. Copy the Webhook URL and click <span className="btn btn-sm none-hover">Continue</span> button.</li>
                                                <li>You can skip the testing part and move forward by clicking <span className="btn btn-sm none-hover">Do this ...</span> Button.</li>
                                                <li>Choose your device you'd like to trigger {`(`}in our case we'll trigger Philips "Hue" light.{`)`}</li>
                                                <li>Choose action you would like to perform with the trigger {`(`}in our case we'll turn off/on the hue light{`)`}</li>
                                                <li>Sign in with your Philips "Hue" account {`(`}or whatever device you choose{`)`}, if you haven't yet and click continue. </li>
                                                <li>We are done! Copy the whole Webhook URL and create a new event inside Toggol's App using that url.</li>
                                            </ol>
                                        </div>
                                        </div>
                                        <div className="container-collapse-icon"><img src={arrowIcon} alt="arrow-icon" className="collapse-arrow-icon" /></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="how-to-trigger" className="p-contaner anchor">
                        <h2 className="title-left-separator">How To Trigger Event</h2>
                        <p>There are 2 ways to trigger an event:</p>
                        <ol className="list">
                            <li>You can simply open the main window of the app and trigger the event manually by pressing the <span className="btn btn-sm none-hover"><img className="icon icon-inside-btn" src={triggerIcon} alt="trigger-icon"/></span> button.</li>
                            <li>Or you can press a shortcut to open the Search Tab (default: <span className="btn btn-sm none-hover">Alt</span> or <span className="btn btn-sm none-hover">Option</span> + <span className="btn btn-sm none-hover">Space</span>) and look up the event's name to want to trigger.</li>
                        </ol>
                    </div>
                    <div id="how-to-shortcut" className="p-contaner anchor">
                        <h2 className="title-left-separator">How To Change Search Bar Shortcut</h2>
                        <p>
                            Open the main window of the Toggol App and click the <span className="btn btn-sm none-hover"><img className="icon icon-inside-btn" src={settingsIcon} alt="cog-icon"/></span> icon in the top right corner of the window to open the settings. Inside the settings window you can change the shortcut for Search Tab by pressing your own shortcuts on the keyboard.
                        </p>
                    </div>
                    <div id="how-to-system-start" className="p-contaner anchor">
                        <h2 className="title-left-separator">How To Launch Toggol App on System Start</h2>
                        <p>
                            Open the main window of the Toggol App and click the <span className="btn btn-sm none-hover"><img className="icon icon-inside-btn" src={settingsIcon} alt="cog-icon"/></span> icon in the top right corner of the window to open the settings. Inside the settings window set a check for "Launch On Start".
                        </p>
                    </div>
                    <div id="forgot-password" className="p-contaner anchor">
                        <h2 className="title-left-separator">Forgot Password? How To Restore?</h2>
                        <p>
                            If you forgot your password or would like to change it, please contact <a className="text-underline link" href="mailto:support@toggol.com">support@toggol.com</a> and we'll be happy to assist.
                        </p>
                    </div>
                    <div id="how-to-update" className="p-contaner anchor">
                        <h2 className="title-left-separator">Update available? How To Update The App?</h2>
                        <p>
                            To update the app you should uninstall the old version and download new one from our official website: <Link className="text-underline link" to="/download">Download</Link>
                        </p>
                    </div>
                    <div id="minimized-app" className="p-contaner anchor">
                        <h2 className="title-left-separator">Closed The App And Can Not Find It Anymore</h2>
                        <p>
                            Toggol app is designed to be always minimized in the tray/menubar to not clutter your desktop space. If the app is running, you should find it in the tray/menubar or simply launch it again and it should pop-up. If you need any help, please contact <a className="text-underline link" href="mailto:support@toggol.com">support@toggol.com</a> and we'll be happy to assist. 
                        </p>
                    </div>
                    <div id="how-to-edit-remove" className="p-contaner">
                        <h2 className="title-left-separator">How To Edit or Remove an Event?</h2>
                        <p>
                            To edit or remove an event you should open the main window of the app and find your event you'd like to edit/remove on the main window. There is a <span className="btn btn-sm none-hover"><img className="icon icon-inside-btn" src={editIcon} alt="edit-icon"/></span> icon for editing and a <span className="btn btn-sm none-hover"><img className="icon icon-inside-btn" src={deleteIcon} alt="delete-icon"/></span> icon for deletion.
                        </p>
                    </div>
                    <div id="what-is-endpoint" className="p-contaner anchor">
                        <h2 className="title-left-separator">What Is The Endpoint?</h2>
                        <p>
                            Endpoint is basically an URL which you should create within IFTTT, Zapier or other platforms in order to trigger your smart device within the Toggol App. You can read more on how to create an endpoint <Link className="text-underline link" to="#how-to-endpoint">here</Link>.
                        </p>
                    </div>
                </div>
            </div>
        </SinglePageLayout>
    )
}


export default HowToUsePage

