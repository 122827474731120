import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import logo from "../images/logo-fixed.svg"
import mobileMenuIcon from "../icons/mobile-menu.svg"
import closeIcon from "../icons/close.svg"

// utils
import fixedMenuOnScroll from '../utils/fixedMenuOnScroll';
import mobileMenu from '../utils/mobileMenu';

const SinglePageHeader = () => {
    useEffect(() => {
        // Fixed menu on scroll
        fixedMenuOnScroll("50px", "200px", 80)
        // Mobile Menu
        mobileMenu()
    }, [])

    return (
        <header className="header">
                <div className="mobile-menu-overlay hidden">
                    <div className="container-mobile-menu">
                        <div className="container-mobile-menu-close"><img className="mobile-menu-close icon" src={closeIcon} alt="mobile menu icon" /></div>
                        <div className="container-logo">
                            <Link to="/"><img src={logo} alt="toggol logo" className="header-logo logo" /></Link>
                        </div>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/#faq">Faq</Link></li>
                            <li><Link to="/how-to-use">How To Use</Link></li>
                            <li><Link to="/download" className="btn-action-dark">Download</Link></li>
                        </ul>
                        <div className="container-created-by">
                            <div className="mention">
                                created by <a href="https://twitter.com/igorpiatkov" target="_blank" className="created-by">igorpiatkov</a>
                            </div>
                        </div>
                    </div> 
                </div>  
            <div className="nav-fixed hidden">
                <div className="nav">
                    <div className="container-logo">
                        <Link to="/"><img src={logo} alt="toggol logo" className="header-logo logo" /></Link>
                    </div>
                    <div className="header-mobile-nav hidden no-click-highlight"><img className="mobile-menu-icon icon" src={mobileMenuIcon} alt="mobile menu icon" /></div>
                    <div className="container-menu">
                        <nav className="header-nav">
                            <ul className="inline-list">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/#faq">Faq</Link></li>
                                <li><Link to="/how-to-use">How To Use</Link></li>
                                <li><Link to="/download" className="btn-action-dark">Download</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="nav nav-main">
                <div className="container-logo">
                    <Link to="/"><img src={logo} alt="toggol logo" className="header-logo logo" /></Link>
                </div>
                <div className="header-mobile-nav hidden"><img className="mobile-menu-icon icon no-click-highlight" src={mobileMenuIcon} alt="mobile menu icon" /></div>
                <div className="container-menu">
                    <nav className="header-nav">
                        <ul className="inline-list">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/#faq">Faq</Link></li>
                            <li><Link to="/how-to-use">How To Use</Link></li>
                            <li><Link to="/download" className="btn-action-dark">Download</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <svg id="shape-single-page-header" viewBox="0 0 1443 639" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="#272729">
                    <animate repeatCount="indefinite" fill="freeze" attributeName="d" dur="10s" values="M 0 550 C 500 400 900 600 1450 500 L 1450 0 L 1450 0 L 1450 0 L 1450 0 L 0 0 Z;M 0 550 C 800 600 800 450 1450 500 L 1450 0 L 1450 0 L 1450 0 L 1450 0 L 0 0 Z;M 0 550 C 500 400 900 600 1450 500 L 1450 0 L 1450 0 L 1450 0 L 1450 0 L 0 0 Z;M 0 550 C 800 600 800 450 1450 500 L 1450 0 L 1450 0 L 1450 0 L 1450 0 L 0 0 Z;M 0 550 C 500 400 900 600 1450 500 L 1450 0 L 1450 0 L 1450 0 L 1450 0 L 0 0 Z"></animate>
                </path>
            </svg>
        </header>
    )
}

SinglePageHeader.propTypes = {
  siteTitle: PropTypes.string,
}

SinglePageHeader.defaultProps = {
  siteTitle: ``,
}

export default SinglePageHeader